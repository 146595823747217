import { PaymentCardPicker } from "@chronos/ui";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "../../../components/Form";
import { mainApi, s3urls } from "../../../helpers/api";
import { getUTMCommentForCP } from "../../../helpers/cloudPayments";
import { googleEvent } from "../../../helpers/metrics";
import { PaymentMethod, paymentMethods, PaymentScheme } from "../../../helpers/payments";
import { getPaymentResult, hash } from "../../finance-capacity/helpers/api";
import { PaymentData } from "../../finance-capacity/helpers/types";
import { getDeviceDetect } from "../helpers/api";
import { useNatalSunMapStore } from "../helpers/store";
import { SunInterpretation } from "../helpers/types";
import { FooterContainer, StyledButton } from './Form';

const BLUR_TEXT = 'Данный фрагмент текста доступен при покупке полного разбора Вашей натальной карты.'

export default function  NatalMapSunResult({
    token,
    partner
}: {
    token: string
    partner: string
}) {

  const isExternal = token === 'natal_map_sun';

  const {mapImage, interpretation, data, setMapImage, setInterpretation, setData} = useNatalSunMapStore();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(paymentMethods[0]);

  const { isMobile } = getDeviceDetect();

  const formData = useNatalSunMapStore((state) => state.data);
  // todo get from store/props
  const storeToken = 'edd9f8ce3df85b4069f5ff67efeb';
  const onBack = () => {
    setMapImage(undefined);
    setInterpretation(undefined);
    setData(undefined);
  };

  // todo rename and refactor, я тут просто накидал, чтобы поднялось :)
  const goToFull: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    if(isExternal) {
        window.location.href = "https://p.chronos.ru/natal-chart-cons";
        return;
    }
      // @ts-ignore // fix-me, add name
      const data: PaymentData = {
          ...formData,
          partner,
          token: storeToken,
          promo : '',
          country: paymentMethod.country,
          natalMapSun: true,
          subtype: 'natalMapSun',
          // captchaToken
      };

      // todo вынести в api
      getPaymentResult(data, (log) => { console.log(log) }).then(async (result) => {
          if (result) {
              const CustomerReceipt = { ...result.receipt, email: formData?.email ?? '' };
              data.type = 'widget';
              data.cloudPayments = { CustomerReceipt };
              data.createdAt = Date.now();
              data.K8S_NAMESPACE = result.K8S_NAMESPACE;
              data.operation = await hash(JSON.stringify(data));
              data.comment = getUTMCommentForCP();

              Object.assign(result.options, {
                  InvoiceId: storeToken,
                  data,
                  email: formData?.email ?? '',
                  requireEmail: true,
                  skin: 'mini',
                  type: 'widget',
                  country: paymentMethod.country,
              });

              const onSuccess = (smtg: any) => {
                googleEvent('payment_natal_successful');
                const site = `${window.location.origin}${window.location.pathname}?${window.location.search.replace('?', '')}`;
                const url = `${site}&date=${data.date}&time=${data.time}&place=${data.place}&email=${data.email}&lat=${data.lat}&lon=${data.lon}#natal_map_sun_full`;
                window.open(url, '_blank');
                //window.location.href = ;
              };

              await mainApi.payment(result.options, PaymentScheme.CHARGE, {onSuccess})
          }
      })
  };

  const goToFullWithAnalyticsByBanner: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    goToFull(e);
    googleEvent('widget_natal_click_pay_banner');
  }

  const goToFullWithAnalyticsByButton: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    if(isMobile) {
        document.getElementById("bannerForPay")?.scrollIntoView({behavior: 'smooth'});
        // return;
    }
    goToFull(e);
    googleEvent('widget_natal_click_pay_button');
  }

  const goToIndi: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    googleEvent('widget_natal_click_trial');
    setTimeout(() => { window.location.href = 'https://indi.chronos.mg/trial?d=14&utm_source=natal'; }, 500 );
  }



  //@ts-ignore
  const interpretationArray: SunInterpretation[] = Array.isArray(interpretation) ? interpretation : [interpretation];

  const bannerFull = `https://widget.chronos.mg/${require('../assets/img/full-banner.jpg')}`;
  const bannerFree = `https://widget.chronos.mg/${require('../assets/img/free-banner.jpg')}`;

  return <Container>
      {!data?.isFull && <Header><BackLink onClick={onBack}>← Поменять данные</BackLink></Header> }
      <img src={mapImage} alt="map" />
      <Interpretation>
        <Title>Описание личности на основе натальной карты</Title>
        <UserInfo>
            {data?.name}&nbsp;&nbsp;&nbsp;
            {data?.date}&nbsp;&nbsp;&nbsp;
            {data?.time.slice(0, 5)}&nbsp;&nbsp;&nbsp;
            {data?.place}
        </UserInfo>

        {!data?.isFull && <Info color="rgb(255 197 32 / 4%);">
            <span>🎁 Проверь свою электронную почту, тебя там ждёт подарок от Chronos</span>
        </Info>}

        {!data?.isFull && <Info>
            <span>Доступно описание личности по Солнцу</span>
            В полной версии у вас будет описание по Луне, Венере, Меркурию и другим планетам. <br /><br />
            Дорогой друг, рады приветствовать тебя в астрологическом пространстве Chronos! Каждая построенная натальная карта разработана сертифицированными астрологами нашего сервиса со стажем от 5 лет. Ты можешь сам убедиться в качестве материалов, прочитав описание по Солнцу. <br /><br/>
            Главное отличие от других сервисов в том, что информация в нашем описании создана реальными астрологами, которые проработали более тысячи комбинаций расположения планет с учетом аспектов и описали это доступным и понятным языком. <br /><br />
        </Info>}

        {interpretationArray.map((interpretation, i ) => <>

            {[interpretation?.sign, interpretation?.house].map((item) => {
                const isBlurred = item.text === 'blurred';
                const text = !isBlurred ? item.text : new Array(7).fill(BLUR_TEXT).join(' ');
                return <> {text && <Block >
                        <h2>{item.title}</h2>
                        <Text dangerouslySetInnerHTML={{__html: text || ''}} blurred={isBlurred}></Text>
                        <Facts blurred={isBlurred}>
                            {item.facts.map(item => {
                                const text = !isBlurred ? item.text : new Array(2).fill(BLUR_TEXT).join(' ');
                                return <Fact>
                                    <FactIcon>{item.icon}</FactIcon>
                                    <FactText>{text}</FactText>
                                </Fact>
                            })}
                        </Facts>
                    </Block>} </>
                }
            )}



            {i % 3 === 0  && !isExternal && !data?.isFull && <div>
                <>
                    <img style={{width: "100%", display: 'block', marginBottom: '1rem'}} src={bannerFree} alt="banner" id="bannerForPay" />
                    {i < 8 && <FooterPaymentContainer style={{marginBottom: '30px'}}>
                        <FooterPaymentTitle alwaysShow={true}>Выберите тип карты для оплаты</FooterPaymentTitle>

                        <PaymentCardPickerContainer>
                            <PaymentCardPicker
                                            items={paymentMethods}
                                            value={paymentMethod}
                                            //@ts-ignore
                                            onChange={setPaymentMethod}
                                showInfo={false}
                            />
                        </PaymentCardPickerContainer>

                        <div>
                            <StyledButton
                                name="submit-button"
                                color="blue"
                                onClick={goToFullWithAnalyticsByBanner}
                                style={{width: '100%'}}
                            >
                                Узнать полное описание личности
                            </StyledButton>
                        </div>

                        <FooterLinks alwaysShow={true}>
                            Нажимая «Узнать полное описание личности», вы принимаете <br /><Link href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>Пользовательское соглашение</Link> и <Link href={s3urls.privacyPolicyRU} target='_blank' rel='noreferrer'>Политику конфиденциальности</Link>
                        </FooterLinks>
                    </FooterPaymentContainer>}
                </>
            </div>}
            {i % 2 === 0  && !isExternal && data?.isFull && <a href="/" onClick={goToIndi} target="_blank" rel="noreferrer" >
                <img style={{width: "100%", display: 'block', marginBottom: '1rem'}} src={bannerFull} alt="banner" />
            </a>}

        </>)}


      </Interpretation>

      {!data?.isFull && <Footer>
        <FooterContainer>
            {isExternal && <StyledButton
                name="submit-button"
                color="blue"
                onClick={goToFullWithAnalyticsByButton}
            >
                Купить разбор натальной карты со скидкой
            </StyledButton>
            }

            {!isExternal && <FooterPaymentContainer>
                <FooterPaymentTitle>Выберите тип карты для оплаты</FooterPaymentTitle>
                <FooterPaymentGrid>
                    <div>
                        <select onChange={(e) => setPaymentMethod(paymentMethods.find(item => item.country === e.target.value)!)}>
                            {paymentMethods.map(item =>
                                <option
                                    key={item.country}
                                    selected={paymentMethod === item}
                                    value={item.country}
                                >{item.title}</option>
                            )}
                        </select>
                    </div>
                    <div>
                        <StyledButton
                            name="submit-button"
                            color="blue"
                            onClick={goToFullWithAnalyticsByButton}
                        >
                            {/* Купить полную версию за {getPrice(paymentMethod)} */}
                            Узнать полное описание личности
                        </StyledButton>
                    </div>
                </FooterPaymentGrid>
                <FooterLinks>
                    Нажимая «Узнать полное описание личности», вы принимаете <br /><Link href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>Пользовательское соглашение</Link> и <Link href={s3urls.privacyPolicyRU} target='_blank' rel='noreferrer'>Политику конфиденциальности</Link>
                </FooterLinks>
            </FooterPaymentContainer>
            }
        </FooterContainer>

      </Footer>}

  </Container>
}

const PaymentCardPickerContainer = styled.div`
    text-align: center;

    > div > div {
        box-sizing: border-box;
    }

    @media (max-width: 768px){
        > div > div > div {
            font-size: 12px;
        }
    }
`

const FooterPaymentContainer = styled.div`
    text-align: left;

    @media (max-width: 768px){
        button {
            font-size: 1rem;
            box-sizing: border-box;
        }
    }
`

const FooterLinks = styled.div<{alwaysShow?: boolean}>`

    text-align: center;
    margin-top: 10px;
    color: var(--text-secondary);

    @media (max-width: 480px){
        font-size: 12px;
    }

    a {
        color: #4093F4 !important;
    }

    ${p => !p.alwaysShow && css`
        @media (max-width: 480px){
            display: none;
        }
    `}
`

const FooterPaymentGrid = styled.div`
    display: flex;
    gap: 16px;

    > div:nth-child(1) {
        flex: 1;

        @media (max-width: 480px){
            display: none;
        }

        > select {
            width: 100%;
            height: 100%;
            border: 1px solid #D0D0D0;
            border-radius: 8px;
            outline: none;
            padding: 0px 16px;
            font-size: 16px;
            color: var(--text-secondary);
            box-sizing: border-box;
        }
    }

    > div:nth-child(2) {
        width: min-content;


        @media (max-width: 480px){
            width: 100%;

        }
    }
`

const FooterPaymentTitle = styled.div<{alwaysShow?: boolean}>`
    font-size: 14px;
    color: var(--text-secondary);
    margin-bottom: 8px;

    ${p => !p.alwaysShow && css`
        @media (max-width: 480px){
            display: none;
        }
    `}

`

const Container = styled.div`
    width: 42.5rem;
    max-width: 85%;
    padding: 1rem 0 6rem;
    margin: 0 auto;

    > img {
        max-width: 100%;
    }
`

const Header = styled.div`
    margin-bottom: 1rem;
    text-align: left;
`

const BackLink = styled.span`
    color: var(--text-secondary);
    cursor: pointer;
`

const Interpretation = styled.div`
    text-align: left;
    text-wrap: balance;
    margin-top: 1rem;
`

const Title = styled.div`
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.25rem;
    color: var(--text-secondary);
    margin-bottom: 0.25rem;
`

const UserInfo = styled.div`
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: var(--text-third);
    margin-bottom: 0.75rem;
`

const Info = styled.div<{color?: string}>`
    margin-bottom: 1rem;
    background: ${p => p.color || 'rgba(64, 147, 244, 0.04);'};
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--text-secondary);

    > span {
        display: block;
        color: var(--text-primary);
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: left;
        margin-bottom: 0.125rem;
    }

`

const Block = styled.div`
    margin-bottom: 2rem;
    color: var(--text-secondary);
    line-height: 1.5rem;

    > h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        margin-bottom: 0.5rem;
    }
`

const Text = styled.div<{blurred: boolean}>`
    font-size: 1rem;
    margin-bottom: 1.25rem;

    ${p => p.blurred && css`
        user-select: none;
        filter: blur(4px);
        -webkit-filter: blur(4px);
    `}
`

const Facts = styled.div<{blurred: boolean}>`
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 460px) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }

    ${p => p.blurred && css`
        user-select: none;
        filter: blur(4px);
        -webkit-filter: blur(4px);
    `}
`

const Fact = styled.div`
    background: var(--color-white);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: 1rem;
`

const FactIcon = styled.div`

`

const FactText = styled.div`

`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;

  background: var(--white-color);
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border-top: 1px solid var(--black-light-color);
  z-index: 1000;

  @media (max-width: 768px){
    button {
        font-size: 1rem;
    }
  }
`;
